import snakecaseKeys from 'snakecase-keys';

import api from './api';
import { JobTemplate, CmsCompatibilityStatus } from '/types/jobTemplateTypes';
import { Error } from '/types/apiResponseTypes';
import { DeepPartial } from '/types/utils';

type Response<D> = Promise<
    | {
          data: D;
      }
    | Error
>;

type OrderMap = {
    [jobTypeId: number]: number;
};

type PartialJobTemplate = DeepPartial<JobTemplate>;

export interface JobMentions {
    pagesWithJobMentions: Array<{
        id: number;
        path: string;
        title: string;
        _type: string;
        slug: string;
    }>;
}

const snakecaseJobTemplate = (jobTemplate: PartialJobTemplate) => {
    return snakecaseKeys(
        {
            ...jobTemplate,
            ...(jobTemplate.jobTypeVersion
                ? {
                      jobTypeVersion: snakecaseKeys(
                          jobTemplate.jobTypeVersion,
                          { deep: false }
                      ),
                  }
                : {}),
        },
        { deep: false }
    );
};

const jobTypeApi = {
    index: (): Response<JobTemplate[]> => api.getSecure('job-type'),

    indexSecure: (): Response<JobTemplate[]> => api.getSecure('job-type'),

    get: (idOrSlug: string | number): Response<JobTemplate> =>
        api.getSecure('job-type/' + idOrSlug),

    getVersion: (jobTypeId: number, versionId: number): Response<JobTemplate> =>
        api.getSecure(`job-type/${jobTypeId}/${versionId}`),

    create: (jobTemplate: JobTemplate): Response<JobTemplate> =>
        api.post('job-type', snakecaseJobTemplate(jobTemplate)),

    update: (
        jobTypeId: number,
        jobTemplate: PartialJobTemplate
    ): Response<JobTemplate> =>
        api.post('job-type/' + jobTypeId, snakecaseJobTemplate(jobTemplate)),

    updateVersion: (
        jobTypeId: number,
        versionId: number,
        jobTemplate: PartialJobTemplate
    ): Response<JobTemplate> =>
        api.post(
            `job-type/${jobTypeId}/${versionId}`,
            snakecaseJobTemplate(jobTemplate)
        ),

    releaseVersion: (
        jobTypeId: number,
        versionId: number
    ):
        | Error
        | {
              job: JobTemplate;
              compatibilityStatus: CmsCompatibilityStatus;
          } => api.post(`job-type/${jobTypeId}/${versionId}/release`),

    delete: (jobTypeId: number): Promise<{} | Error> =>
        api.delete(`job-type/delete/${jobTypeId}`),

    deleteVersion: (
        jobTypeId: number,
        versionId: number
    ): Promise<{} | Error> =>
        api.delete(`job-type/delete/${jobTypeId}/${versionId}`),

    checkSlug: (
        slug: string,
        jobTypeId: number
    ): Promise<{ available: boolean } | Error> =>
        api.post('job-type/check-slug', { slug, job_type_id: jobTypeId }),

    updateOrder: (idOrderMapping: OrderMap): Promise<{} | Error> =>
        api.post('job-type/order', idOrderMapping),

    recheckExpiredMaterial: (): Promise<{} | Error> =>
        api.post('job-type/recheck-expired-material'),

    getCmsMentions: (jobTypeId: number): Promise<JobMentions | Error> =>
        api.post(`job-type/cms-mentions/${jobTypeId}`),
};

export default jobTypeApi;
