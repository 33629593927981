import { curry } from 'ramda';

import { Row, Col } from '/front';
import { SolarInstallationDetailsModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';

export default function SolarInstallationDetailsModuleSettings({
    module,
    onChange,
}: ModuleProps<SolarInstallationDetailsModuleType>) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, containerSize } = module.data;

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ContainerSizeSetting
                        containerSize={containerSize}
                        onChange={change('containerSize')}
                    />
                </Col>
            </Row>
        </>
    );
}
