import PostCodeCheckerModule from './PostCodeCheckerModule';
import PostCodeCheckerModuleSettings from './PostCodeCheckerModuleSettings';

export default {
    _key: '',
    _type: 'post_code_checker',
    title: 'Post code checker',
    description: 'Post code checker in regular or compact version',
    image: '/modules/postCodeChecker.svg',
    moduleComponent: PostCodeCheckerModule,
    settingsComponent: PostCodeCheckerModuleSettings,
    data: {
        margin: 'mb-60',
        theme: 'default',
    },
};
