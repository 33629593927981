import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from '/lib/utils/moment';

import { colors, effects } from '/css';
import Icon from '/components/ui/Icon';
import Callendar from '/components/ui/Callendar';
import { useOnClickOutside, isObject } from '/lib/helpers';

const Select = ({
    placeholder = '',
    label = '',
    options = [],
    onChange = (val) => val,
    onOptionsVisibilityChange = (bool) => bool,
    icon = '',
    medium = false,
    small = false,
    raised = false,
    id = '',
    multiple = false,
    checkboxes = false,
    callendar = false,
    callendarProps = {},
    minWidth = '',
    disabled = false,
    className = '',
    style = {},
    invalid = false,
    searchable = false,
    ...props
}) => {
    const { value } = props;
    const isEmpty = (x) =>
        !x || (Array.isArray(x) && !x.length) ? true : false;

    const [optionsVisible, setOptionsVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [childrenExpanded, setChildrenExpanded] = useState('');

    const selectWrapperRef = useRef();
    const searchInputRef = useRef();

    useEffect(() => {
        if (optionsVisible && searchInputRef?.current)
            searchInputRef.current.focus();
        if (!optionsVisible) setSearchQuery('');
        if (onOptionsVisibilityChange)
            onOptionsVisibilityChange(optionsVisible);
    }, [optionsVisible]);

    const onSelect = (option) => {
        if (disabled) return;

        const {
            name,
            value: optionValue,
            children,
            separator,
            isDisabled,
        } = isObject(option)
            ? option
            : { name: option, value: option, isDisabled: false };

        if (separator || isDisabled) return;

        if (multiple && checkboxes && isSelected(option)) {
            onChange(
                value.filter((valueOption) => {
                    return valueOption.value !== option.value;
                })
            );
        } else if (multiple) {
            // dont add items that are already sellected
            if (
                (value || []).some(
                    (val) => JSON.stringify(val) === JSON.stringify(option)
                )
            )
                return;

            onChange([...(value || []), option]);
        } else {
            if (children) {
                setChildrenExpanded(childrenExpanded ? '' : optionValue);
            } else {
                onChange(optionValue);
            }
        }
        if (!children && !checkboxes) setOptionsVisible(false);
    };

    const removeFromSelected = (e, item) => {
        if (disabled) return;
        e.stopPropagation();
        setOptionsVisible(false);
        onChange(
            value.filter((option) => {
                if (isObject(option)) return option.value !== item.value;
                return option !== item;
            })
        );
    };

    const isSelected = (option) => {
        const val = isObject(option) ? option.value : option;

        if (multiple) {
            return ~(value || [])
                .map((option) => (isObject(option) ? option.value : option))
                .indexOf(val);
        } else {
            return val === value;
        }
    };

    const selectedValue = () => {
        if (searchable && optionsVisible) {
            return (
                <input
                    placeholder="Søk"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search"
                    ref={searchInputRef}
                />
            );
        }

        if (value !== 0 && isEmpty(value) && placeholder) return placeholder;

        if (checkboxes) {
            if (Array.isArray(value)) {
                return (
                    <div className="selected-options-count">
                        <div>{placeholder}</div>
                        <div>{value.length} valgt</div>
                    </div>
                );
            }
            return placeholder;
        }

        if (Array.isArray(value)) {
            return value.map((item, i) => {
                return (
                    <div key={i} className="value-item">
                        <span>{item.name || item.label || item}</span>
                        <Icon
                            icon="empty-field"
                            className="empty"
                            onClick={(e) => removeFromSelected(e, item)}
                        />
                    </div>
                );
            });
        }

        if (callendar) {
            const valueMoment = moment(value);
            return valueMoment.isValid()
                ? valueMoment.format('DD. MMMM YYYY')
                : 'invalid date value';
        }

        return (
            options
                .map((o) => (typeof o === 'string' ? { name: o, value: o } : o))
                .filter((o) => o.value === value)[0] || {}
        ).name;
    };

    useOnClickOutside(selectWrapperRef, () => {
        setOptionsVisible(false);
    });

    const search = (option) => {
        const needle = searchQuery.trim().toLowerCase();
        const haystack = (
            isObject(option) ? `${option.value} ${option.name}` : option
        ).toLowerCase();

        if (!needle) return true;

        return ~haystack.indexOf(needle);
    };

    return (
        <>
            {label && <Label>{label}</Label>}
            <Wrapper
                className={className}
                ref={selectWrapperRef}
                style={style}
                optionsVisible={optionsVisible}
                callendar={callendar}
                disabled={disabled}
                medium={medium}
                small={small}
                invalid={invalid}
                minWidth={minWidth}
                raised={raised}
            >
                <Icon
                    icon="dropdown-small"
                    fill={disabled ? colors.grey : null}
                />
                <div
                    className={[
                        `select`,
                        `value-${value}`,
                        !isEmpty(value) && multiple && !checkboxes
                            ? 'multiple'
                            : null,
                    ].join(' ')}
                    value={value}
                    onClick={(e) => {
                        !disabled && setOptionsVisible(!optionsVisible);
                    }}
                    id={id}
                >
                    {icon && typeof icon === 'string' && (
                        <Icon
                            icon={icon}
                            className="select-icon"
                            style={{ marginRight: '8px' }}
                        />
                    )}
                    {icon && typeof icon !== 'string' && icon}
                    {selectedValue()}
                </div>

                {optionsVisible && (
                    <div
                        className={`options ${checkboxes ? 'checkboxes' : ''}`}
                    >
                        {options &&
                            options.filter(search).map((option, i) => {
                                const {
                                    name,
                                    value,
                                    children,
                                    separator,
                                    isDisabled,
                                    count,
                                } = isObject(option)
                                    ? option
                                    : {
                                          name: option,
                                          value: option,
                                          isDisabled: false,
                                      };
                                return (
                                    <div
                                        key={i}
                                        value={value}
                                        className={[
                                            'option',
                                            separator && 'separator',
                                            isDisabled && 'is-disabled',
                                        ]
                                            .filter((c) => c)
                                            .join(' ')}
                                        onClick={() => onSelect(option)}
                                    >
                                        <div
                                            className={`option-value ${
                                                isSelected(option)
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                        >
                                            {name || separator}
                                            {checkboxes && !!count && (
                                                <div className="option-count">
                                                    {count}
                                                </div>
                                            )}
                                            {checkboxes && (
                                                <input
                                                    type="checkbox"
                                                    checked={isSelected(option)}
                                                    className="checkbox new-style"
                                                    readOnly
                                                />
                                            )}
                                            {children && (
                                                <Icon
                                                    icon="dropdown-small"
                                                    className="option-children-indicator-icon"
                                                />
                                            )}
                                        </div>
                                        {children && (
                                            <div
                                                className={`option-children ${
                                                    childrenExpanded === value
                                                        ? 'expanded'
                                                        : ''
                                                }`}
                                            >
                                                {(children || []).map(
                                                    (child, j) => (
                                                        <div
                                                            key={j}
                                                            onClick={() =>
                                                                onSelect(child)
                                                            }
                                                            className={`option-child`}
                                                        >
                                                            {isObject(child)
                                                                ? child.name
                                                                : child}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        {callendar && (
                            <Callendar
                                daysPerWeek={
                                    isObject(callendar) && callendar.daysPerWeek
                                        ? callendar.daysPerWeek
                                        : 7
                                }
                                canSelectDay
                                selected={[value]}
                                onDaySelect={(day) => {
                                    setOptionsVisible(false);
                                    onChange(day.format('YYYY-MM-DD'));
                                }}
                                {...(callendarProps || {})}
                            />
                        )}
                    </div>
                )}
            </Wrapper>
        </>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    medium: PropTypes.bool,
    small: PropTypes.bool,
    invalid: PropTypes.bool,
    raised: PropTypes.bool,
    //value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array, // array of option objects ({name:'one', value:1}) OR strings
};

export default Select;

const Label = styled.label`
    font-size: 17px;
    font-family: 'gellix-semi';
    display: block;
    margin-bottom: 8px;
`;

const Wrapper = styled.div`
    position: relative;
    min-width: 240px;
    max-width: 100%;

    ${(props) =>
        props.minWidth
            ? `
        min-width: ${props.minWidth};
    `
            : ``}

    ${(props) =>
        props.callendar
            ? `
        min-width: 272px;
        max-width: 550px;
    `
            : ``}

    > svg {
        position: absolute;
        top: 16px;
        z-index: 1;
        transform: ${(props) =>
            props.optionsVisible ? `rotate(180deg)` : `none`};
        right: 16px;
        pointer-events: none;
    }

    .select {
        user-select: none;
        min-height: 58px;
        border: 3px solid rgba(76, 71, 108, 0.08) !important;
        background: #fff;
        outline: none;
        border-radius: 12px;
        padding: 13px 46px 15px 16px;
        font-size: 17px !important;
        line-height: 1.3;
        color: ${colors.balck};
        transition: border 0.2s ease-in-out;
        position: relative;
        background-image: none;
        cursor: pointer;
        font-family: 'gellix-semi';
        display: flex;
        align-items: center;
        @media (pointer: fine) {
            &:hover {
                border: 3px solid ${colors.purpleLight}!important;
            }
        }
        ${(props) =>
            props.invalid
                ? `border: 3px solid ${colors.orange}!important;`
                : ``}

        &.multiple {
            padding-left: 6px;
            padding-bottom: 0px;
            padding-top: 6px;
            flex-wrap: wrap;
        }

        .value-item {
            border-radius: 12px;
            background: ${colors.blueGrey};
            padding: 7px 10px 7px 12px;
            font-size: 16px;
            line-height: 1;
            margin: 0 10px 6px 0;
            display: inline-flex;
            align-items: center;
            height: 39px;
            > span {
                position: relative;
                top: -1px;
            }
            .empty {
                cursor: pointer;
                margin-left: 8px;
                &:hover {
                    @media (pointer: fine) {
                        path {
                            fill: ${colors.orange};
                        }
                    }
                }
            }
        }

        .search {
            padding: 0;
            font-size: 16px;
            line-height: 1;
            height: auto;
            border: 0;
            background: transparent;
            font-family: 'gellix-semi';
        }

        .selected-options-count {
            width: 100%;
            display: flex;

            div:nth-child(1) {
                flex: 1;
            }

            div:nth-child(2) {
                color: ${colors.grey};
                font-family: 'gellix-medium';
                font-size: 15px;
                margin-top: 1px;
            }
        }
    }
    .options {
        user-select: none;
        position: absolute;
        top: calc(100% + 8px);
        width: 100%;
        min-width: ${(props) => (props.minWidth ? props.minWidth : '240px')};
        left: 0;
        z-index: 120;
        background: #fff;
        ${effects.shadow};
        border-radius: 12px;
        transition: all 0.2s ease-in-out;
        max-height: 272px;
        overflow: auto;

        &.checkboxes {
            > .option .option-value {
                padding: 12px 50px 12px 16px;

                &.selected {
                }
            }

            .option-value {
                .option-count {
                    position: absolute;
                    top: 50%;
                    right: 52px;
                    transform: translateY(-50%);

                    background-color: #ffd8bf;
                    font-size: 13px;
                    padding: 2px 9px 4px;
                    border-radius: 12px;
                    color: ${colors.black};
                }
                .checkbox {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                    margin: 0;
                }
            }
        }

        ${(props) =>
            props.callendar
                ? `
            padding: 16px;
            max-height: 595px;
        `
                : ''};
        &:hover {
            @media (pointer: fine) {
                ${effects.shadowHover};
            }
        }
        .callendar-nav {
            max-width: 100%;
        }
        .day-wrapper {
            &:before {
                padding-top: 100%;
                @media (max-width: 767px) {
                    padding-top: 150%;
                }
            }
        }
        > .option {
            position: relative;

            .option-value {
                font-family: 'gellix-semi';
                font-size: 17px;
                min-height: 36px;
                padding: 6px 16px 7px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                text-align: left;
                &:hover {
                    @media (pointer: fine) {
                        background: ${colors.blueGrey};
                        color: ${colors.purpleLight};
                    }
                }
                &.selected {
                    color: ${colors.purple}!important;
                }
            }

            &.is-disabled .option-value:hover {
                cursor: default;
                @media (pointer: fine) {
                    background: transparent;
                    color: ${colors.black};
                }
            }

            &.separator {
                .option-value {
                    cursor: default;
                    padding-bottom: 0px;
                    min-height: 23px;
                    font-family: 'gellix-medium';
                    background: #fff !important;
                    color: ${colors.grey}!important;
                    font-size: 15px;
                }
            }

            .option-children-indicator-icon {
                position: absolute;
                top: 6px;
                right: 16px;
            }
            .option-children {
                padding: 0 16px;
                display: none;
                &.expanded {
                    display: block;
                }
                .option-child {
                    padding: 6px 0;
                    border-bottom: 1px solid ${colors.blueGrey};
                    font-size: 15px;
                    cursor: pointer;
                    &:first-child {
                        border-top: 1px solid ${colors.blueGrey};
                    }
                    &:hover {
                        @media (pointer: fine) {
                            color: ${colors.purpleLight};
                        }
                    }
                }
            }
        }
    }

    ${({ medium }) =>
        medium
            ? `
        .select {
            min-height:54px;
            padding:11px 46px 13px 16px;
            font-size: 17px!important;
            line-height: 1.3;
            .select-icon {
                bottom: -1px;
                position: relative;
            }
            &.multiple {
                padding-left: 6px;
                padding-bottom: 0px;
                padding-top: 6px;
            }
            .value-item {
                border-radius: 12px;
                padding: 6px 10px 6px 12px;
                font-size:16px;
                line-height:1;
                margin: 0 10px 6px 0;
                height:36px;
            }
        }
    `
            : ``};

    ${({ small }) =>
        small
            ? `
        > svg {
            top:6px;
            right:6px;
        }
        .select {
            min-height:36px;
            padding:2px 28px 4px 8px;
            font-size: 17px!important;
            line-height: 1.3;
            &.multiple {
                padding-left: 6px;
                padding-bottom: 0px;
                padding-top: 3px;
            }
            .select-icon {
                transform: scale(.9);
            }
            .value-item {
                padding: 2px 0px 2px 12px;
                margin-bottom:3px;
                font-size:14px;
                line-height:1;
                margin: 0 10px 6px 0;
                height:17px;
                > svg {
                    margin-left:-2px;
                    margin-left:6px;
                }
            }
        }
    `
            : ``};

    ${({ disabled }) =>
        disabled
            ? `
        .select {
            background: #F6F6F6!important;
            border: 3px solid #F6F6F6!important;
            color: ${colors.grey}!important;
            cursor: default!important;
            .value-item {
                background: #e6e6e6;
                > svg {
                    cursor: default!important;
                    path {
                        fill: ${colors.grey}!important;
                    }
                }
            }
            @media (pointer: fine) { &:hover {
                border: 3px solid transparent!important;
            }}
            .select-icon {
                &.icon-clock path { fill: ${colors.grey}; }
                &.icon-calendar path { fill: ${colors.grey}; }
            }
        }
    `
            : ``};

    ${({ raised }) =>
        raised
            ? `
        .select {
            background: #fff;
            border: 3px solid transparent!important;
            ${effects.shadow};
        }
    `
            : ``};

    ${({ raised, disabled }) =>
        raised && disabled
            ? `
        .select {
            background: #fff!important;
            border: 3px solid transparent!important;
            @media (pointer: fine) { &:hover {
                border: 3px solid transparent!important;
            }}
            .select-icon {
                &.icon-clock path { fill: ${colors.grey}; }
                &.icon-calendar path { fill: ${colors.grey}; }
            }
        }
    `
            : ``};
`;
