import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'ramda';

import Container from '/components/Container';
import { Text, Buttons, ui } from '/front';
import { array, insertAtIndex } from '/lib/helpers';
import { HeadingModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import Icon from '/components/ui/Icon';

type Data = HeadingModuleType['data'];

export default memo(function HeadingModule({
    module,
    onChange,
}: ModuleProps<HeadingModuleType>) {
    const {
        margin,
        textAlignment,
        text,
        withDescription,
        description,
        withButtons,
        buttons,
        headingSize,
        headingTag,
        withBulletList,
        bulletList,
        headingContainer,
    } = module.data;

    const change =
        <K extends keyof Data>(key: K) =>
        (value: Data[K]) =>
            onChange({ data: { ...module.data, [key]: value } });

    const { Actions } = ui();

    useEffect(() => {
        if (withBulletList && isEmpty(array(bulletList))) {
            change('bulletList')(['']);
        }
        if (!withBulletList && !isEmpty(array(bulletList))) {
            change('bulletList')([]);
        }
    }, [withBulletList]);

    const addListItem = (index: number) => {
        change('bulletList')(insertAtIndex(bulletList, index + 1, ''));
    };

    const removeListItem = (index: number) => {
        change('bulletList')(bulletList.filter((_, i: number) => i !== index));
    };

    return (
        <StyledContainer className={`${margin} text-align-${textAlignment}`}>
            <div
                className={`heading-text-container container-${headingContainer}`}
            >
                <Text
                    value={text}
                    onChange={change('text')}
                    placeholder="Heading"
                    multiline
                    className={`heading-text heading-${
                        headingSize || 'xl'
                    } text-align-${textAlignment}`}
                    tag={headingTag || 'h1'}
                />
            </div>
            {withDescription && (
                <div className="pt-32 description-text-container">
                    <Text
                        value={description}
                        onChange={change('description')}
                        placeholder="Description text"
                        multiline
                        className={`description-text paragraph-large text-align-${textAlignment}`}
                        tag="p"
                    />
                </div>
            )}
            {withBulletList && (
                <ul className="pt-80 bullet-list">
                    {(bulletList || []).map((text: string, i: number) => (
                        <li key={i} className="mb-24 has-front-actions">
                            <Icon icon="spoton" />
                            <Text
                                value={text}
                                onChange={(val: string) => {
                                    let updatedList = [...bulletList];
                                    updatedList[i] = val;
                                    change('bulletList')(updatedList);
                                }}
                                placeholder="..."
                                multiline
                                className={`heading-medium text-align-left`}
                                tag="div"
                            />
                            <Actions
                                className="actions"
                                onAdd={() => addListItem(i)}
                                onRemove={
                                    bulletList.length === 1
                                        ? undefined
                                        : () => removeListItem(i)
                                }
                                align="top-left"
                            />
                        </li>
                    ))}
                </ul>
            )}
            {withButtons && (
                <Buttons
                    buttons={buttons}
                    onChange={change('buttons')}
                    style={{ justifyContent: 'center' }}
                />
            )}
        </StyledContainer>
    );
});

const StyledContainer = styled(Container)`
    .heading-text-container {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 800px) {
            width: 100%;
        }
        &.container-wide {
            width: 100%;
        }
    }
    .description-text-container {
        width: 565px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    .bullet-list {
        max-width: 577px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 767px) {
            padding-top: 32px;
        }
        li {
            display: flex;
            > svg {
                margin-right: 16px;
                min-width: 32px;
                min-height: 32px;
                bottom: -2px;
                position: relative;
                @media (max-width: 767px) {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
            .heading-medium,
            .editable-wrapper {
                width: 100%;
                top: -2px;
                position: relative;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .actions {
                padding-right: 8px;
                left: 0;
                top: 50%;
                transform: translateX(-100%) translateY(-50%);
                height: 100%;
            }
        }
    }
    @media (max-width: 767px) {
        .buttons-container {
            width: 100%;
            > div {
                width: 100%;
                .button {
                    margin: 0 0 20px;
                    width: 100%;
                }
            }
        }
    }

    &.text-align-left {
        .description-text-container {
            margin-left: 0;
        }
        .buttons-container {
            justify-content: flex-start !important;
        }
    }

    &.text-align-right {
        .description-text-container {
            margin-right: 0;
        }
        .buttons-container {
            justify-content: flex-end !important;
        }
    }
`;
