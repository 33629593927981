import { curry } from 'ramda';
import { useEffect, useState } from 'react';

import { ui, Row, Col, error, config } from '/front';
import jobCategoryApi from '/api/cms/frontCategoryApi';

export default function JobCategoryModuleSettings({ module, onChange }) {
    const { ColorPicker, Select, Input } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { background, margin, categoryId, trackingId } = module.data;

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            const resp = await jobCategoryApi.get({ type: 'job-category' });
            if (resp.error) return error(resp.error);
            setCategories(resp);
        })();
    }, []);

    // render module - get job data for selected category
    useEffect(() => {
        if (!categoryId) return;

        (async () => {
            const { data, error: e } = await config.api.page.renderModule(
                module
            );
            if (e) return error(e);
            onChange({ data });
        })();
    }, [categoryId]);

    return (
        <>
            <Row>
                <Col width={[4, 6]} className="mb-24">
                    <Select
                        placeholder="Select category"
                        medium
                        value={categoryId}
                        onChange={change('categoryId')}
                        options={categories.map((c) => ({
                            name: c.title,
                            value: c._id,
                        }))}
                        style={{ width: '100%' }}
                        label="Category"
                    />
                </Col>
                <Col width={[4, 6]} className="mb-24">
                    <Select
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={[4, 6]} className="mb-24">
                    <ColorPicker
                        value={background}
                        onChange={change('background')}
                        medium
                        style={{ width: '100%' }}
                        label="Background"
                    />
                </Col>
                <Col width={12} className="mb-24">
                    <Input
                        value={trackingId}
                        onChange={change('trackingId')}
                        medium
                        style={{ width: '100%' }}
                        label="Tracking ID"
                    />
                </Col>
            </Row>
        </>
    );
}
