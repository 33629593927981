import { curry } from 'ramda';
import { useState, useEffect } from 'react';

import { ui, Row, Col, error } from '/front';
import jobCategoryApi from '/api/cms/frontCategoryApi';

export default function JobListModuleSettings({ module, onChange }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            const resp = await jobCategoryApi.get({ type: 'job-category' });
            if (resp.error) return error(resp.error);
            setCategories(resp);
        })();
    }, []);

    const { Select, Input } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, categoryId, trackingId } = module.data;

    return (
        <>
            <Row className="mb-24">
                <Col width={[6]}>
                    <Select
                        value={categoryId}
                        options={[
                            { name: 'Alle', value: 0 },
                            ...categories.map((c) => ({
                                name: c.title,
                                value: c._id,
                            })),
                        ]}
                        onChange={change('categoryId')}
                        medium
                        style={{ width: '100%' }}
                        label="Category"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={12}>
                    <Input
                        value={trackingId}
                        onChange={change('trackingId')}
                        medium
                        style={{ width: '100%' }}
                        label="Tracking ID"
                    />
                </Col>
            </Row>
        </>
    );
}
