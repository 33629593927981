import { curry } from 'ramda';
import styled from 'styled-components';

import { ui, Row, Col } from '/front';
import { ImageGridModuleType } from './types';
import { colors } from '/css';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function ImageGridModuleSettings({
    module,
    onChange,
}: ModuleProps<ImageGridModuleType>) {
    const { Label } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, theme } = module.data;

    return (
        <Row>
            <Col width={[6, 12]} className="mb-24">
                <MarginSetting margin={margin} onChange={change('margin')} />
            </Col>
            <Col width={[12]} className="mb-24">
                <Label style={{ marginBottom: '4px' }}>Theme</Label>
                <ThemeOptions className={`theme-${theme}`}>
                    <div onClick={() => change('theme', 'small-big')}>
                        <svg
                            width="120"
                            height="70"
                            viewBox="0 0 120 70"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M112 0C116.418 0 120 3.58172 120 8V62C120 66.4183 116.418 70 112 70H44V0H112ZM39 0H8C3.58173 0 0 3.58172 0 8V32V62C0 66.4183 3.58173 70 8 70H39V32V0Z"
                                fill="#F2F2F5"
                            />
                        </svg>
                    </div>
                    <div onClick={() => change('theme', 'big-small')}>
                        <svg
                            width="120"
                            height="70"
                            viewBox="0 0 120 70"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M112 0H81V32V70H112C116.418 70 120 66.4183 120 62V32V8C120 3.58172 116.418 0 112 0Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M0 8C0 3.58172 3.58172 0 8 0H76V70H8C3.58172 70 0 66.4183 0 62V8Z"
                                fill="#F2F2F5"
                            />
                        </svg>
                    </div>
                    <div onClick={() => change('theme', 'big-small-small')}>
                        <svg
                            width="120"
                            height="70"
                            viewBox="0 0 120 70"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M81 0H112C116.418 0 120 3.58172 120 8V32H81V0Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M81 38H120V62C120 66.4183 116.418 70 112 70H81V38Z"
                                fill="#F2F2F5"
                            />
                            <path
                                d="M0 8C0 3.58172 3.58172 0 8 0H76V70H8C3.58172 70 0 66.4183 0 62V8Z"
                                fill="#F2F2F5"
                            />
                        </svg>
                    </div>
                </ThemeOptions>
            </Col>
        </Row>
    );
}

const ThemeOptions = styled.div`
    display: flex;
    div {
        width: 132px;
        height: 82px;
        padding: 3px;
        margin-right: 18px;
        border: 3px solid transparent;
        border-radius: 14px;
        cursor: pointer;
        svg {
            opacity: 0.6;
        }
    }
    &.theme-small-big div:nth-child(1),
    &.theme-big-small div:nth-child(2),
    &.theme-big-small-small div:nth-child(3) {
        border: 3px solid ${colors.purple};
    }
`;
