import { curry } from 'ramda';

import { ui, Row, Col } from '/front';
import { HeadingModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function HeadingModuleSettings({
    module,
    onChange,
}: ModuleProps<HeadingModuleType>) {
    const { Select, Switch } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        margin,
        textAlignment,
        withDescription,
        withButtons,
        headingTag,
        headingSize,
        buttons,
        withBulletList,
        headingContainer,
    } = module.data;

    const newButton = {
        id: '',
        url: '',
        slug: '',
        type: '',
        path: '',
        title: 'Button',
        target: '_self',
        buttonSize: 'default',
        buttonType: 'primary',
    };

    return (
        <Row>
            <Col width={[6]} className="mb-24">
                <MarginSetting margin={margin} onChange={change('margin')} />
            </Col>
            <Col width={[6]} className="mb-24">
                <Select
                    value={textAlignment}
                    options={['center', 'left', 'right']}
                    onChange={change('textAlignment')}
                    medium
                    style={{ width: '100%' }}
                    label="Text alignment"
                />
            </Col>
            <Col width={[6]} className="mb-24">
                <Select
                    value={headingSize}
                    options={['xl', 'xxl']}
                    onChange={change('headingSize')}
                    medium
                    style={{ width: '100%' }}
                    label="Heading size"
                />
            </Col>
            <Col width={[6]} className="mb-24">
                <Select
                    value={headingTag}
                    options={[
                        { name: 'Page title', value: 'h1' },
                        { name: 'Subtitle', value: 'h2' },
                        { name: 'other', value: 'div' },
                    ]}
                    onChange={change('headingTag')}
                    medium
                    style={{ width: '100%' }}
                    label="Heading type"
                />
            </Col>
            <Col width={[6]} className="mb-24">
                <Select
                    value={headingContainer || 'default'}
                    options={[
                        { name: 'Default', value: 'default' },
                        { name: 'Wide', value: 'wide' },
                    ]}
                    onChange={change('headingContainer')}
                    medium
                    style={{ width: '100%' }}
                    label="Heading text container type"
                />
            </Col>
            <Col width={[6]} className="mb-24">
                <Switch
                    on={withDescription}
                    onChange={change('withDescription')}
                    style={{ marginTop: '5px' }}
                    label="With description text"
                />
            </Col>
            <Col width={[6]} className="mb-12">
                <Switch
                    on={withButtons}
                    onChange={(on: boolean) => {
                        onChange({
                            data: {
                                ...module.data,
                                withButtons: on,
                                buttons:
                                    buttons && buttons.length
                                        ? buttons
                                        : [newButton],
                            },
                        });
                    }}
                    style={{ marginTop: '5px' }}
                    label="With call to action buttons"
                />
            </Col>
            <Col width={[6]} className="mb-12">
                <Switch
                    on={withBulletList}
                    onChange={change('withBulletList')}
                    style={{ marginTop: '5px' }}
                    label="With SpotOn bullet list"
                />
            </Col>
        </Row>
    );
}
