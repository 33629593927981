import { curry } from 'ramda';

import { ui, Row, Col } from '/front';
import { TableModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';

export default function TableModuleSettings({
    module,
    onChange,
}: ModuleProps<TableModuleType>) {
    const { Select, Switch } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        margin,
        containerSize,
        useRowHeaders,
        rowHeaderContentType,
        textAlignment,
        useFixedLayout,
    } = module.data;

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ContainerSizeSetting
                        containerSize={containerSize}
                        onChange={change('containerSize')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Switch
                        on={useRowHeaders}
                        onChange={change('useRowHeaders')}
                        label="Use row headers"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={rowHeaderContentType}
                        options={[
                            { name: 'Image', value: 'image' },
                            { name: 'Text', value: 'text' },
                        ]}
                        onChange={change('rowHeaderContentType')}
                        medium
                        disabled={!useRowHeaders}
                        style={{ width: '100%' }}
                        label="Row Header content type"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Switch
                        on={useFixedLayout}
                        onChange={change('useFixedLayout')}
                        label="Use fixed table layout"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={textAlignment}
                        options={[
                            { name: 'Center', value: 'center' },
                            { name: 'Top left', value: 'top-left' },
                        ]}
                        onChange={change('textAlignment')}
                        medium
                        style={{ width: '100%' }}
                        label="Text alignment"
                    />
                </Col>
            </Row>
        </>
    );
}
