import React, { useState, useEffect, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { colors } from '/css';
import Container from '/components/Container';
import { userIs } from '/lib/helpers';
import { useOrderState } from '/store/orderStore';
import { useHydration } from '/store/store.helpers';
import { useUserState } from '/store/userStore';

import Intro from './Intro';
import Message from './Message';
import NotificationForm from './NotificationForm';
import CodeCheckerForm from './CodeCheckerForm';
import { JobTemplate } from '/types/jobTemplateTypes';

import { UserRole } from '/types/userTypes';
interface Props {
    children?: ReactNode;
    className?: string;
    isFormVisible?: boolean;
    rememberCode?: boolean;
    theme?: 'default' | 'mini-start' | undefined;
    hideForUserRoles?: UserRole | UserRole[];
    jobType?: JobTemplate['typeKey'];
}

export default function PostCodeChecker({
    children,
    className,
    isFormVisible,
    rememberCode = true,
    theme = 'default',
    hideForUserRoles,
    jobType,
}: Props) {
    const postCode = useOrderState((state) => state.postCode);
    const postCodeValid = useOrderState((state) => state.postCodeValid);
    const postCodeNotificationRequested = useOrderState(
        (state) => state.postCodeNotificationRequested
    );
    const setOrderPostCodeValidity = useOrderState(
        (state) => state.setOrderPostCodeValidity
    );
    const setOrderPostCode = useOrderState((state) => state.setOrderPostCode);
    const setOrderPostCodeNotificationRequest = useOrderState(
        (state) => state.setOrderPostCodeNotificationRequest
    );
    const setOrderPostCodeSupportedJobTypes = useOrderState(
        (state) => state.setOrderPostCodeSupportedJobTypes
    );

    const user = useUserState((state) => state.user);

    const hydrated = useHydration();

    const router = useRouter();

    const [formVisible, setFormVisible] = useState(false);
    const [delay, setDelay] = useState(false);

    const codeUndetermined = !postCode && !postCodeValid;

    useEffect(() => {
        if (!rememberCode)
            return () => {
                setOrderPostCode('');
                setOrderPostCodeValidity(false);
                setOrderPostCodeNotificationRequest(false);
                setOrderPostCodeSupportedJobTypes([]);
            };
    }, []);

    useEffect(() => {
        if (hydrated && isFormVisible && codeUndetermined) {
            showCheckerForm();
        }
    }, [hydrated]);

    useEffect(() => {
        if (router.query.postnummer) {
            showCheckerForm();
            setOrderPostCode('');
            setOrderPostCodeValidity(false);
            setOrderPostCodeNotificationRequest(false);
            setOrderPostCodeSupportedJobTypes([]);
        }
    }, [router.query]);

    const showCheckerForm = () => {
        setFormVisible(true);
        setDelay(true);
    };

    const backgroundColor = (() => {
        if (postCodeValid === true) return colors.greenLight;
        if (
            !codeUndetermined &&
            postCodeValid === false &&
            postCodeNotificationRequested === false
        )
            return colors.orangeLighter;
        if (
            !codeUndetermined &&
            postCodeValid === false &&
            postCodeNotificationRequested === true
        )
            return colors.purpleLighter;
        return colors.purpleLighter;
    })();

    const onCodeReset = () => {
        setOrderPostCode('');
        setOrderPostCodeValidity(false);
        setOrderPostCodeNotificationRequest(false);
        setOrderPostCodeSupportedJobTypes([]);
        showCheckerForm();
    };

    const { messageIcon, messageText } = useMemo(() => {
        let messageIcon = 'check';
        let messageText = 'Vi er tilgjengelige i ditt område';

        if (
            codeUndetermined &&
            postCodeValid === false &&
            postCodeNotificationRequested === false
        ) {
            messageIcon = 'cross';
            messageText =
                'Vi beklager. Vi er ikke tilgjengelige i ditt område ennå, men snart.';
        }

        if (
            !codeUndetermined &&
            postCodeValid === false &&
            postCodeNotificationRequested === true
        ) {
            messageIcon = 'mail';
            messageText =
                'Vi gir deg beskjed så snart vi er tilgjengelige der du bor.';
        }

        return { messageIcon, messageText };
    }, [postCodeValid, postCodeNotificationRequested]);

    if (!hydrated) return null;

    if (hideForUserRoles && userIs(user, hideForUserRoles)) return null;

    return (
        <Wrapper
            id="checker"
            className={[
                'theme-' + theme,
                postCodeValid && !delay && 'code-validated',
                postCodeValid && delay && 'code-validated-message',
                className,
            ]
                .filter((c) => c)
                .join(' ')}
            style={{ backgroundColor }}
        >
            <Container>
                {codeUndetermined && !formVisible && (
                    <Intro theme={theme} onCheck={showCheckerForm} />
                )}

                {!codeUndetermined && (
                    <Message
                        icon={messageIcon}
                        message={messageText}
                        onCodeReset={onCodeReset}
                        postCode={postCode}
                    >
                        {postCodeValid === true ? children : undefined}
                    </Message>
                )}
                {!codeUndetermined &&
                    postCodeValid === false &&
                    postCodeNotificationRequested === false && (
                        <NotificationForm />
                    )}
                {formVisible && (
                    <CodeCheckerForm
                        afterCheck={() => {
                            setFormVisible(false);
                            setTimeout(() => setDelay(false), 3000);
                        }}
                    />
                )}
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    .code-checker-message {
        transition: all 0.2s ease-in-out;
    }
    &.code-validated-message {
        height: 235px;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: 198px;
        }
        @media (max-width: 767px) {
            height: 152px;
        }
    }
    &.code-validated {
        height: 80px;
        .code-checker-message {
            padding-top: 24px;
            padding-bottom: 28px;
            .checkmark,
            .message {
                display: none;
            }
        }
    }
`;
