import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { curry } from 'ramda';
import { useAtom } from 'jotai';

import Container from '/components/Container';
import { Text, config, Buttons, Image, ui } from '/front';
import { HeroModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import moduleConfig from './';
import { createDataValueGetter } from '/front/lib/helpers';
import { headerThemeModeAtom } from '/components/layout/Header';

export default memo(function HeroModule({
    module,
    onChange,
    addAction,
}: ModuleProps<HeroModuleType>) {
    const [, setHeaderTheme] = useAtom(headerThemeModeAtom);

    const { editMode } = ui();

    useEffect(() => {
        if (editMode) addAction('style', { top: '80px' });
    }, [editMode]);

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const dataValue = createDataValueGetter(module, moduleConfig);
    const image = dataValue('image');
    const imageBelowText = dataValue('imagePlacement') === 'below-text';
    const imageAoveText = !imageBelowText;
    const withButtons = dataValue('buttons').length > 0;
    const topBleed = dataValue('topBleed');
    const textColor = dataValue('textColor');
    const backgroundImage = dataValue('backgroundImage')
        ? config.imageUrl(dataValue('backgroundImage')).width(2000).url()
        : '';

    useEffect(() => {
        setHeaderTheme(textColor);
        return () => setHeaderTheme('black');
    }, [textColor]);

    return (
        <Wrapper
            style={{
                backgroundColor: dataValue('backgroundColor'),
                backgroundImage: backgroundImage
                    ? `url(${backgroundImage})`
                    : 'none',
            }}
            backgroundOverlayColor={dataValue('backgroundOverlayColor')}
            backgroundOverlayOpacity={dataValue('backgroundOverlayOpacity')}
            className={[
                dataValue('margin'),
                `text-color-${textColor}`,
                `image-${dataValue('imagePlacement')}`,
                topBleed ? 'top-bleed' : '',
            ].join(' ')}
        >
            <Container
                className={[
                    `pt-${dataValue('verticalPadding')}`,
                    `pb-${dataValue('verticalPadding')}`,
                    'container',
                ].join(' ')}
            >
                <div>
                    <Text
                        value={dataValue('heading')}
                        onChange={change('heading')}
                        placeholder="Heading"
                        multiline
                        className={`heading-xl mb-32 text-align-center`}
                        tag="h3"
                    />
                    <Text
                        value={dataValue('description')}
                        onChange={change('description')}
                        placeholder="Description"
                        multiline
                        className={[
                            'paragraph-medium',
                            withButtons ? 'mb-32' : '',
                            dataValue('withImage') && imageBelowText
                                ? 'mb-48'
                                : '',
                            'text-align-center',
                        ].join(' ')}
                        tag="p"
                    />
                    {dataValue('withImage') && (
                        <div
                            className={[
                                'image',
                                imageAoveText ? 'mb-48' : '',
                                withButtons && imageBelowText ? 'mb-48' : '',
                                dataValue('imageRoundedCorners') ? 'br oh' : '',
                            ].join(' ')}
                        >
                            <Image
                                src={config
                                    .imageUrl(image)
                                    .width(600)
                                    .height(400)
                                    .url()}
                                image={image}
                                alt={image?.alt || 'Hero image'}
                                onChange={change('image')}
                            />
                        </div>
                    )}
                    <Buttons
                        buttons={dataValue('buttons')}
                        onChange={change('buttons')}
                    />
                </div>
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div<{
    backgroundOverlayColor: string;
    backgroundOverlayOpacity: number;
}>`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    &.top-bleed {
        margin-top: -64px;
        padding-top: 64px;
        @media (max-width: 767px) {
            margin-top: 0;
            padding-top: 0;
        }
    }
    :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${(props) => props.backgroundOverlayColor};
        opacity: ${(props) => props.backgroundOverlayOpacity / 100};
    }
    .container > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .editable-wrapper,
    .heading-xl,
    .paragraph-medium {
        display: block;
    }
    .container > div > div:nth-child(1),
    .heading-xl {
        width: 800px;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    .container > div > div:nth-child(2),
    .paragraph-medium {
        width: 565px;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &.text-color-white {
        .editable-wrapper,
        .heading-xl,
        .paragraph-medium,
        .container > div > div:nth-child(1),
        .heading-xl,
        .container > div > div:nth-child(2),
        .paragraph-medium {
            color: white;
        }
    }
    .image {
        max-width: 600px;
        img {
            display: block;
        }
    }
    &.image-above-text {
        .image {
            order: 1;
        }
        .container > div > div:nth-child(1),
        .heading-xl {
            order: 2;
        }
        .container > div > div:nth-child(2),
        .paragraph-medium {
            order: 3;
        }
        .buttons-container {
            order: 4;
        }
    }
    .buttons-container {
        padding-top: 0;
    }
    @media (max-width: 767px) {
        .buttons-container {
            width: 100%;
            > div {
                width: 100%;
                .button {
                    margin: 0 0 20px;
                    width: 100%;
                }
            }
        }
    }
`;
