import styled from 'styled-components';
import { ReactNode } from 'react';

import { colors } from '/css';
import Paragraph from '/components/ui/Paragraph';
import Icon from '/components/ui/Icon';

interface Props {
    message: string;
    icon: string;
    children?: ReactNode;
    onCodeReset: () => void;
    postCode: string;
}

export default function Message({
    message,
    icon,
    children,
    onCodeReset,
    postCode,
}: Props) {
    return (
        <Wrapper className="pt-60 pb-60 code-checker-message">
            <Icon icon={icon} className="mb-12 checkmark" />
            <Paragraph
                large
                centered
                maxWidth="480px"
                className="mb-12 message"
            >
                {message}
            </Paragraph>
            <div className="submited-code">
                Postnummer:{' '}
                <span onClick={onCodeReset}>
                    {postCode}
                    <Icon icon="dropdown-small" fill={colors.purple} />
                </span>
            </div>
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .submited-code {
        font-size: 17px;
        font-family: 'gellix-semi';
        span {
            color: ${colors.purple};
            cursor: pointer;
        }
        svg {
            margin-left: 2px;
            position: relative;
            bottom: -5px;
        }
    }
`;
