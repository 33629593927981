import styled from 'styled-components';

import Paragraph from '/components/ui/Paragraph';
import Link from '/components/ui/Link';
import Button from '/components/ui/buttons/Button';

interface Props {
    theme?: 'default' | 'mini-start' | undefined;
    onCheck: () => void;
}

export default function Intro({ theme, onCheck }: Props) {
    return (
        <Wrapper className={'pt-60 pb-60 theme-' + theme}>
            <Paragraph
                large={theme === 'default'}
                centered={theme === 'default'}
                maxWidth={theme === 'default' ? `480px` : ``}
                className={theme === 'default' ? `mb-16` : ``}
            >
                Vi er tilgjengelige i nesten hele Norge. Sjekk der du bor.
            </Paragraph>
            {theme === 'default' && (
                <Link underlined large onClick={onCheck}>
                    Sjekk
                </Link>
            )}
            {theme === 'mini-start' && (
                <Button size="small" onClick={onCheck}>
                    Sjekk
                </Button>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    &.theme-mini-start {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;
    }
`;
