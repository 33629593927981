import styled from 'styled-components';
import { identity } from 'ramda';

import { array } from '/lib/helpers';

export default function Actions({
    className = '',
    children,
    centered = false,
    fullWidth = false,
}) {
    return (
        <Wrapper
            className={[
                fullWidth && 'full-width',
                centered && 'centered',
                className,
            ]
                .filter((x) => x)
                .join(' ')}
            childrenCount={array(children).filter(identity).length}
        >
            {children}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    > button,
    > .button,
    > .radio-button {
        @media (max-width: 480px) {
            width: 100%;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    &.full-width {
        > button,
        > .button,
        > .radio-button,
        > a {
            width: calc(${(props) => 100 / props.childrenCount}% - 8px);
            min-width: auto !important;
            @media (max-width: 480px) {
                width: 100%;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    &.centered {
        justify-content: center;
        > button,
        > .button,
        > .radio-button,
        > a {
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }

            @media (max-width: 480px) {
                margin-right: 0;
            }
        }
    }
`;
