import { useEffect, useState } from 'react';

import useAutoLogin from '/hooks/useAutoLogin';
import { config, ui } from '/front';
import Page, { PageTypes } from '/front/types/frontPageType';
import ModuleTemplate from '/front/types/frontModuleTemplate';

export default function usePageState(page: Page | null) {
    const { page: editedPage, setPage } = config.usePage();

    const { canEditPages } = config.useUser();

    const { editMode } = ui();

    useAutoLogin();

    const [pageData, setPageData] = useState(page);

    useEffect(() => {
        setPageData(page);
    }, [page]);

    useEffect(() => {
        return () => {
            setPage(null);
        };
    }, []);

    useEffect(() => {
        if (!canEditPages) return;

        if (editMode) {
            setPage(pageData);
            return;
        }

        getPageData().then(() => {
            setPage(null);
        });
    }, [editMode]);

    const getPageData = async () => {
        if (!pageData?._id) return;

        if (page?.id && page?._type === PageTypes.MODULE_TEMPLATE) {
            const templateResp = await config.api.moduleTemplate.single(
                page?.id
            );
            if (!('error' in templateResp)) {
                setPageData(moduleTemplateToPage(templateResp));
            }
            return;
        }

        const pageResp = await config.api.page.get({ id: pageData._id });
        if (!('error' in pageResp)) {
            setPageData(pageResp[0]);
        }
    };

    return {
        pageData, // this will hold oage data from the server & while in edit mode upodated page content so editors wont have to wait for revalidation
        editedPage, // this will hold zustand page state chnages while in edit mode
    };
}

export const moduleTemplateToPage = (template: ModuleTemplate): Page => ({
    _id: String(template.id),
    _type: PageTypes.MODULE_TEMPLATE,
    id: template.id,
    parentId: null,
    draftId: null,
    published: true,
    title: template.title,
    slug: String(template.id),
    path: `module-templates/${template.id}`,
    modules: template.data,
    seo: { title: '', description: '', keywords: [], image: null },
    createdAt: template.createdAt,
    updatedAt: template.updatedAt,
    background: '#fff',
    usedOn: template.usedOn,
});
