import { curry } from 'ramda';

import { ui, Row, Col } from '/front';
import { ImageModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function ImageModuleSettings({
    module,
    onChange,
}: ModuleProps<ImageModuleType>) {
    const { Input, Switch, Label } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, width, roundedBorders } = module.data;

    return (
        <>
            <Row>
                <Col width={[6, 6, 12]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <Input
                        value={String(width)}
                        onChange={(val: string) => {
                            const w = Number(val.replace(/\D/g, ''));
                            change('width')(w);
                        }}
                        medium
                        style={{ width: '100%' }}
                        label="Image width (px)"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24 ft-align-center">
                    <Switch
                        on={roundedBorders}
                        onChange={change('roundedBorders')}
                    />
                    <Label
                        style={{ display: 'inline-block', marginLeft: '12px' }}
                    >
                        Rounded borders
                    </Label>
                </Col>
            </Row>
        </>
    );
}
