import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import { colors, effects } from '/css';
import Icon from '/components/ui/Icon';
import Heading from '/components/ui/Heading';

const Card = (props) => {
    const {
        title,
        clickable = false,
        description,
        link,
        linkInNewTab = false,
        grey = false,
        darkGrey = false,
        flat = false,
        style = {},
        children,
        className = '',
        expanded = false,
        toggleLabel,
        toggleLabelOpened = false,
        onClick,
        childrenCollapsable,
        onOpen,
        onClose,
        onLinkClick,
        rightSection,
        rightSectionStyle = {},
        titleFullWidthOnMobile = false,
        rightSectionFullWidthOnMobile = false,
        dataAttr = {},
    } = props;

    const [childrenVisible, setChildrenVisible] = useState(
        childrenCollapsable ? expanded : true
    );

    const childrenContainerRef = useRef(null);

    useEffect(() => {
        if (childrenCollapsable === false && childrenVisible)
            setChildrenVisible(false);
    }, [childrenCollapsable]);

    const ToggleLink = () =>
        !childrenCollapsable ? null : (
            <button
                className={[
                    'expand-link',
                    childrenVisible ? 'expanded' : null,
                ].join(' ')}
            >
                {toggleLabel
                    ? !childrenVisible
                        ? toggleLabel
                        : toggleLabelOpened
                        ? toggleLabelOpened
                        : toggleLabel
                    : 'Se eller endre'}{' '}
                <Icon
                    icon={
                        childrenVisible
                            ? 'dropdown-small-open'
                            : 'dropdown-small'
                    }
                    fill={colors.purple}
                />
            </button>
        );

    const toggle = () => {
        setChildrenVisible(!childrenVisible);
        if (!childrenVisible && onOpen) onOpen();
        if (childrenVisible && onClose) onClose();
    };

    return (
        <Wrapper
            tabIndex={clickable ? '0' : '-1'}
            grey={grey}
            darkGrey={darkGrey}
            flat={flat}
            clickable={clickable || childrenCollapsable}
            style={style}
            onClick={(e) => {
                if (onClick) onClick(e);

                if (childrenCollapsable) {
                    if (e.target === childrenContainerRef.current) return;
                    if (
                        childrenContainerRef.current &&
                        childrenContainerRef.current.contains(e.target)
                    )
                        return;

                    toggle();
                }
            }}
            className={'p-24 pl-32 pr-32 ' + (className || '')}
            {...dataAttr}
        >
            {link && (title || description) && (
                <Link
                    prefetch={false}
                    href={link.href}
                    onClick={() =>
                        typeof onLinkClick === 'function' && onLinkClick()
                    }
                    target={linkInNewTab ? '_blank' : '_self'}
                >
                    <div
                        className={[
                            'title-desc',
                            titleFullWidthOnMobile
                                ? 'full-width-on-mobile'
                                : null,
                        ].join(' ')}
                    >
                        <Heading medium tag="h6">
                            {title}
                        </Heading>
                        {description && <span>{description}</span>}
                        <div>
                            <ToggleLink />
                        </div>
                    </div>
                    {rightSection && (
                        <div
                            className={[
                                'right-section',
                                rightSectionFullWidthOnMobile
                                    ? 'full-width-on-mobile'
                                    : null,
                            ].join(' ')}
                            style={rightSectionStyle}
                        >
                            {rightSection}
                        </div>
                    )}
                </Link>
            )}
            {!link && (title || description) && (
                <div
                    className={[
                        'title-desc',
                        titleFullWidthOnMobile ? 'full-width-on-mobile' : null,
                    ].join(' ')}
                >
                    <Heading medium tag="h6">
                        {title}
                    </Heading>
                    {description && <span>{description}</span>}
                    <ToggleLink />
                </div>
            )}

            {rightSection && !link && (
                <div
                    className={[
                        'right-section',
                        rightSectionFullWidthOnMobile
                            ? 'full-width-on-mobile'
                            : null,
                    ].join(' ')}
                    style={rightSectionStyle}
                >
                    {rightSection}
                </div>
            )}

            {children && childrenVisible && (
                <div className="children-container" ref={childrenContainerRef}>
                    {children}
                </div>
            )}
        </Wrapper>
    );
};

export default Card;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${effects.shadow}
    background: #fff;
    position: relative;
    flex-wrap: wrap;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    border: 3px solid white;

    > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        width: 100%;
    }

    ${({ clickable }) =>
        clickable
            ? `
        cursor:pointer;
        &:hover { ${effects.shadowHover} }
    `
            : ``}

    ${({ grey }) =>
        grey
            ? `
        background: #ececef;
        border: 3px solid #ececef;
        box-shadow:none!important;
    `
            : ``}

    ${({ darkGrey }) =>
        darkGrey
            ? `
        background: #ECEDF0;
        border: 3px solid #ECEDF0;
        box-shadow:none!important;
    `
            : ``}

    ${({ flat }) =>
        flat
            ? `
        background: transparent;
        border: 3px solid #ECEDF0;
        box-shadow:none!important;
    `
            : ``}

    .title-desc {
        position: relative;
        max-width: 70%;
        @media (max-width: 767px) {
            max-width: 65%;
            &.full-width-on-mobile {
                max-width: 100%;
            }
        }

        strong {
            font-family: 'gellix-semi';
            font-size: 17px;
            display: block;
            line-height: 1;
        }

        span {
            color: ${colors.grey};
            font-size: 15px;
            font-family: 'gellix-medium';
            display: block;
            line-height: 1;
            padding-top: 8px;
        }
    }

    .right-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 767px) {
            &.full-width-on-mobile {
                justify-content: flex-start;
                width: 100%;
            }
        }
    }

    .expand-link {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-size: 17px;
        color: ${colors.purple};
        margin-top: 8px;
        &.expanded {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    .children-container {
        cursor: default;
        width: 100%;
        position: relative;
        width: calc(100% + 36px);
        margin: 0 -18px -18px -18px;
        padding: 0 18px 18px 18px;
        @media (min-width: 768px) and (max-width: 1024px) {
            width: calc(100% + 28px);
            margin: 0 -14px -14px -14px;
            padding: 0 14px 14px 14px;
        }
        @media (max-width: 767px) {
            width: calc(100% + 20px);
            margin: 0 -10px -10px -10px;
            padding: 0 10px 10px 10px;
        }
    }
`;
