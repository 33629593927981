import { memo } from 'react';
import styled from 'styled-components';
import { curry } from 'ramda';

import Container from '/components/Container';
import { Text, Image, config, Button, ui } from '/front';
import ItemCarousel from '/components/layout/ItemCarousel';
import JobCard from '/components/layout/JobCard';
import ScrollObserver from '/components/ScrollObserver';
import { trackSelectItem, trackViewItemList } from '/lib/ga-data-layer';
import useIsJobSupported from '/hooks/useIsJobSupported';

export default memo(function JobModule({ module, onChange }) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        title,
        description,
        margin,
        background,
        image,
        categoryLink,
        jobs,
        trackingId = 'Job category list',
    } = module.data;

    const { editMode } = ui();

    const onVisible = (revealCount) => {
        if (!editMode && revealCount === 1) {
            trackViewItemList(jobs, trackingId);
        }
    };

    const isJobSupported = useIsJobSupported({ resourceType: 'cart' });

    return (
        <ScrollObserver onVisible={onVisible}>
            <Wrapper className={margin} style={{ backgroundColor: background }}>
                <Container>
                    <div className="info pt-80">
                        <div className="info-text">
                            <Text
                                value={title}
                                onChange={change('title')}
                                placeholder="Title"
                                multiline
                                className={`heading-large`}
                                tag="h4"
                            />
                            <Text
                                value={description}
                                onChange={change('description')}
                                placeholder="Description"
                                multiline
                                className={`paragraph-medium mb-32`}
                                tag="p"
                            />
                            <div className="button-wrapper">
                                <Button
                                    value={categoryLink}
                                    onChange={change('categoryLink')}
                                    link={config.pageUrl(categoryLink)}
                                    className="button"
                                >
                                    {categoryLink?.title || 'Category link'}
                                </Button>
                            </div>
                        </div>
                        <div className="image">
                            <div>
                                <Image
                                    image={image}
                                    src={config
                                        .imageUrl(image)
                                        .width(580)
                                        .height(315)
                                        .url()}
                                    onChange={change('image')}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="jobs pt-60 pb-80">
                    <Container>
                        <ItemCarousel
                            className="job-carousel"
                            onStage={[4, 3, 1]}
                            itemPixelGap={24}
                        >
                            {(jobs || []).map((job, jobIndex) => (
                                <JobCard
                                    key={job.slug}
                                    linkProps={{
                                        href: `/bestilling/jobb/${job.slug}`,
                                    }}
                                    onClick={() => {
                                        if (!editMode) {
                                            trackSelectItem(
                                                job,
                                                trackingId,
                                                jobIndex
                                            );
                                        }
                                    }}
                                    openLinksInNewTab={false}
                                    job={{
                                        typeKey: job?.typeKey,
                                        jobTypeVersion: {
                                            name: job.name,
                                            image: job.image,
                                            slug: job.slug,
                                        },
                                        totals: {
                                            startingPrice: job.startingPrice,
                                        },
                                    }}
                                    supported={isJobSupported(job?.typeKey)}
                                />
                            ))}
                        </ItemCarousel>
                    </Container>
                </div>
            </Wrapper>
        </ScrollObserver>
    );
});

const Wrapper = styled.div`
    .info {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
        .info-text {
            min-width: 50%;
            width: 50%;
            padding-right: 120px;

            .heading-large {
                margin-bottom: 16px;
            }
            .paragraph-medium {
            }
            .button-wrapper {
                display: inline-block;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                padding-right: 50px;
            }
            @media (max-width: 767px) {
                min-width: 100%;
                padding-right: 0;
                margin-bottom: 32px;
                .button-wrapper {
                    display: block;
                    .button {
                        width: 100%;
                    }
                }
            }
        }
        .image {
            @media (max-width: 767px) {
                width: 100%;
            }
            > div {
                border-radius: 12px;
                overflow: hidden;
                img {
                    display: block;
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .jobs {
        .job-item {
            width: 100%;
            height: 100%;
            margin: 0;
            a {
                user-drag: none;
                height: 100%;
            }
            .icon {
                img {
                    user-drag: none;
                }
                @media (max-width: 390px) {
                    height: 180px;
                }
            }
            .job-title {
                @media (max-width: 390px) {
                    font-size: 26px;
                    line-height: 32px;
                }
            }
            .price {
                @media (max-width: 390px) {
                    font-size: 20px;
                    padding-top: 8px;
                }
            }
        }
        /*@media(max-width: 767px) {
            .row {
                transform: none!important;
                flex-wrap: wrap;
                .item {
                    margin-bottom: 24px;
                    &:last-child, &:nth-last-child(2)  {
                        margin-bottom: 0;
                    }
                }
            }
        }*/
    }
`;
