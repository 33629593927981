import { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '/css';

interface Props {
    children: ReactNode;
}

const HorisontalScroller = ({ children, ...rest }: Props) => {
    const InnerWrapperRef = useRef(null);

    return (
        <div style={{ position: 'relative' }}>
            <Wrapper ref={InnerWrapperRef} {...rest}>
                {children}
            </Wrapper>
        </div>
    );
};

export default HorisontalScroller;

const Wrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    text-align: left;
    padding-bottom: 18px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(76, 71, 108, 0.2);
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
    }
    &::-webkit-scrollbar {
        height: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.black};
        border-top: 3px solid #fff;
        border-bottom: 3px solid #fff;
        border-radius: 5px;
        cursor: pointer;
    }
    &:hover::-webkit-scrollbar-thumb {
        border-top: 0px solid #fff;
        border-bottom: 0px solid #fff;
    }
    @media (max-width: 767px) {
        &::-webkit-scrollbar-thumb {
            border-top: 0px solid #fff;
            border-bottom: 0px solid #fff;
        }
    }

    > * {
        display: inline-block;
        margin-right: 8px;
    }
`;
