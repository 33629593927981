import { memo, useMemo, useState } from 'react';
import { useOrderState } from '/store/orderStore';
import { ModuleProps } from '/front/types/frontModuleType';
import { PostCodeCheckerModuleType } from './types';
import styled from 'styled-components';
import Container from '/components/Container';
import { colors } from '/css';
import Button from '/components/ui/buttons/Button';
import { config } from '/front';
import PostCodeCheckerModal from '../../features/post-code-checker-modal/PostCodeCheckerModal';
import PostCodeStatusButton from '/components/ui/PostCodeStatusButton';
import { JobTemplateType } from '/types/jobTemplateTypes';
import usePostCodeState from '/hooks/usePostCodeState';

export default memo(function PostCodeCheckerModule({
    module,
}: ModuleProps<PostCodeCheckerModuleType>) {
    const { margin, theme, containerWidth, image } = module.data;
    const { postCode, postCodeSupportedJobTypes } = usePostCodeState();

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const imageUrl = image ? config.imageUrl(image).width(1160).url() : '';

    const content = useMemo(() => {
        if (!postCode) {
            return (
                <>
                    <p>
                        Vi er tilgjengelige i nesten hele Norge. Sjekk der du
                        bor.
                    </p>
                    <Button
                        className="cta-button"
                        onClick={() => setModalVisible(true)}
                    >
                        Sjekk ditt postnummer
                    </Button>
                </>
            );
        }

        if (postCodeSupportedJobTypes.length === 0) {
            return (
                <>
                    <PostCodeStatusButton
                        withPlaceName={true}
                        className="code-status-button"
                    />
                    <p>Vi er ikke tilgjengelig i ditt område.</p>
                    <Button className="cta-button" link="/nyhetsbrev">
                        Skriv deg på venteliste
                    </Button>
                </>
            );
        }

        return (
            <>
                <PostCodeStatusButton
                    withPlaceName={true}
                    className="code-status-button"
                />
                <p>Vi leverer i ditt område</p>
                <Button className="cta-button" link="/nettbutikk/alle">
                    Se tilgjengelige jobber
                </Button>
            </>
        );
    }, [postCode, postCodeSupportedJobTypes]);

    return (
        <div className={margin}>
            {modalVisible && (
                <PostCodeCheckerModal
                    closeRequestCallback={() => setModalVisible(false)}
                />
            )}
            <Container
                large={containerWidth === 'large'}
                medium={containerWidth === 'medium'}
                small={containerWidth === 'small'}
            >
                <ModuleWrapper className={`theme-${theme}`}>
                    {theme === 'image' && (
                        <div
                            className="image"
                            style={{ backgroundImage: 'url(' + imageUrl + ')' }}
                        />
                    )}
                    <div className="main-content">{content}</div>
                </ModuleWrapper>
            </Container>
        </div>
    );
});

const ModuleWrapper = styled.div`
    background-color: ${colors.purpleM7};
    border-radius: 16px;
    display: flex;

    @media (max-width: 715px) {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .image {
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 16px 0 0 16px;

        @media (max-width: 715px) {
            width: 100%;
            padding-top: 46%;
            border-radius: 16px 16px 0 0;
        }
    }

    .main-content {
        text-align: center;
        padding: 72px;
        width: 50%;

        @media (max-width: 960px) {
            padding-left: 40px;
            padding-right: 40px;
        }
        @media (max-width: 767px) {
            width: 100%;
            padding: 40px;
        }

        p {
            max-width: 520px;
            min-width: 0 !important;
            margin: 0 auto;
            text-align: center;
            font-size: 28px;
            font-family: 'gellix-bold';

            @media (max-width: 715px) {
                max-width: 320px;
            }

            @media (max-width: 640px) {
                font-size: 24px;
            }
        }

        .code-status-button {
            margin-bottom: 16px;
        }

        .cta-button {
            margin-top: 32px;

            @media (max-width: 715px) {
                margin-top: 24px;
            }
        }
    }

    &.theme-simple {
        .image {
            display: none;
        }

        .main-content {
            width: 100%;
        }
    }
`;
