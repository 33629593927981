import Layout from '/components/Layout';
import HeadMeta from '/components/HeadMeta';
import settingsApi from '/api/settings';
import ErrorPage from '/pages/404';
import { config } from '/front';
import usePageState from '/hooks/usePageState';
import Modules from '/front/presentation/Modules';
import getMenus from '/lib/utils/getMenus';

const Home = (props) => {
    const { page, menus } = props;
    const { pageData, editedPage } = usePageState(page);

    if (pageData === null) return <ErrorPage />;

    return (
        <Layout menus={menus} isCmsPage={pageData}>
            <HeadMeta
                {...(pageData?.seo || {})}
                links={[
                    {
                        rel: 'canonical',
                        href: `${process.env.NEXT_PUBLIC_APP_URL}`,
                    },
                ]}
            />
            <div
                style={{
                    minHeight: '100vh',
                    background:
                        editedPage?.background ||
                        pageData?.background ||
                        '#ffffff',
                }}
            >
                <Modules
                    modules={
                        editedPage ? editedPage?.modules : pageData.modules
                    }
                />
            </div>
        </Layout>
    );
};

export const getStaticProps = async (context) => {
    const settings = await settingsApi.all();
    const pageResp = await config.api.page.get({
        id: settings.homePageId,
        published: true,
        type: ['page'],
    });

    return {
        props: {
            page: 'error' in pageResp ? null : pageResp[0],
            menus: await getMenus(),
        },
        revalidate: 60,
    };
};

export default Home;
