import { memo } from 'react';

import { userIs } from '/lib/helpers';
import { useUserState } from '/store/userStore';

import PostCodeChecker from '/components/layout/PostCodeChecker';

export default memo(function PostCodeCheckerModule({ module, onChange }) {
    const user = useUserState((state) => state.user);

    return (
        <div className={module.data.margin}>
            <PostCodeChecker
                rememberCode={!userIs(user, 'SALES_PARTNER_SELLER')}
                theme={module.data.theme || 'default'}
                hideForUserRoles={'SALES_PARTNER_SELLER'}
            />
        </div>
    );
});
