import { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { curry } from 'ramda';
import moment from '/lib/utils/moment';
import { useAtom } from 'jotai';
import { Text, config, ui, Buttons } from '/front';
import { ArticleIntroModuleType } from './types';
import { headerThemeModeAtom } from '/components/layout/Header';

import { ModuleProps } from '/front/types/frontModuleType';

export default memo(function ArticleIntroModule({
    module,
    onChange,
    addAction,
    createdAt,
}: ModuleProps<ArticleIntroModuleType>) {
    const {
        margin,
        description,
        title,
        textColor,
        image,
        overlayColor,
        overlayOpacity,
        hideDate,
        withButtons,
        buttons,
    } = module.data;

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const [mainOutOfView, setMainOutOfView] = useState<boolean>(false);

    const [, setHeaderTheme] = useAtom(headerThemeModeAtom);

    const { editMode } = ui();

    const mainRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (editMode) addAction('style', { top: '80px' });
    }, [editMode]);

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        return () => document.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        setHeaderTheme(textColor);
        return () => setHeaderTheme('black');
    }, [textColor]);

    const onScroll = (e: any) => {
        if (!mainRef.current) return;
        const mainBCR = mainRef.current.getBoundingClientRect();
        if (mainBCR.y * -1 >= mainBCR.height) {
            setMainOutOfView(true);
        } else {
            setMainOutOfView(false);
        }
    };

    const bgd = image ? config.imageUrl(image).width(2000).url() : '';

    const textStyle =
        textColor === 'white' ? { color: '#fff' } : { color: '#1E1A21' };

    return (
        <Wrapper
            className={`${margin} ${mainOutOfView ? 'main-out-of-view' : ''}`}
        >
            <div
                className="background"
                style={{
                    backgroundImage: bgd ? `url(${bgd})` : 'none',
                }}
            />
            <div
                className="main"
                ref={mainRef}
                style={{
                    backgroundColor: `rgba(${
                        overlayColor !== 'black' ? '255,255,255' : '0,0,0'
                    },${(overlayOpacity || 0) / 100})`,
                }}
            >
                <div className={`text ${!editMode ? 'animate-text' : ''}`}>
                    {!hideDate && (
                        <time
                            className="heading-medium semi mb-12 text-align-center block time"
                            style={textStyle}
                        >
                            {moment(createdAt).format('DD MMM YYYY')}
                        </time>
                    )}
                    <Text
                        value={title}
                        onChange={change('title')}
                        placeholder="Heading"
                        multiline
                        className={`heading-xl mb-24 text-align-center title`}
                        style={textStyle}
                        tag="h1"
                    />
                    <Text
                        value={description}
                        onChange={change('description')}
                        placeholder="Description"
                        multiline
                        className={`heading-medium semi text-align-center desc`}
                        style={textStyle}
                        tag="p"
                    />
                    {withButtons && (
                        <Buttons
                            buttons={buttons || []}
                            onChange={change('buttons')}
                        />
                    )}
                </div>
            </div>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    margin-top: -64px;
    height: 100vh;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 56px);
        left: 0;
        width: 100%;
        height: calc(100vh + 56px);
        display: block;
        background: #fff;
        border-top-left-radius: 56px;
        border-top-right-radius: 56px;
        @media (max-width: 767px) {
            top: calc(100% - 36px);
            height: calc(100vh + 36px);
            border-top-left-radius: 36px;
            border-top-right-radius: 36px;
        }
    }
    .background {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 0;
        background-color: #fff;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .main {
        height: 100vh;
        //z-index: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text {
            width: 860px;
            max-width: 100%;
            @media (max-width: 900px) {
                padding-left: 25px;
                padding-right: 25px;
            }
            .buttons-container {
                justify-content: center;
                @media (max-width: 767px) {
                    width: 100%;
                    > div {
                        width: 100%;
                        .button {
                            margin: 0 0 20px;
                            width: 100%;
                        }
                    }
                }
            }
            &.animate-text {
                .time,
                .title,
                .desc {
                    opacity: 0;
                    animation: fadeIn;
                    animation-duration: 1.2s;
                    animation-fill-mode: forwards;
                }
                .time {
                    animation-delay: 0.6s;
                }
                .title {
                    animation-delay: 0.2s;
                }
                .desc {
                    animation-delay: 0.6s;
                }
                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        //transform: scale(.5);
                    }
                    to {
                        opacity: 1;
                        //transform: scale(1);
                    }
                }
            }
        }
    }
    &.main-out-of-view {
        .background {
            display: none;
        }
    }
`;
