import api from './api';
import { EmailSubscriptionType } from '/types/EmailSubscriptionType';

const subscribe = async (
    post_code: string,
    email: string,
    notification_types: EmailSubscriptionType[]
): Promise<true> => {
    const res = await api.post('newsletter/subscribe', {
        post_code,
        email,
        notification_types,
    });

    if (res.error) throw res;

    return true;
};

const unsubscribe = async (email: string): Promise<true> => {
    const res = await api.post('newsletter/unsubscribe', { email });

    if (res.error) throw res;

    return true;
};

const unsubscribeByUuid = async (uuid: string): Promise<true> => {
    const res = await api.post('newsletter/unsubscribe', { uuid });

    if (res.error) throw res;

    return true;
};

const newsletterApi = {
    subscribe,
    unsubscribe,
    unsubscribeByUuid,
};

export default newsletterApi;
