import { curry } from 'ramda';
import { ContactCallbackModuleType } from './type';
import { ui, Row, Col } from '/front';
import MarginSetting from './../_shared/MarginSetting';
import { ModuleProps } from '/front/types/frontModuleType';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';

const ContactCallbackModuleSettings = ({
    module,
    onChange,
}: ModuleProps<ContactCallbackModuleType>) => {
    const { Select, Input } = ui();

    const change = curry((key: string, value: any) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={module.data.type}
                        options={[
                            {
                                name: 'Solceller',
                                value: 'solar',
                            },
                            {
                                name: 'Standard',
                                value: 'regular',
                            },
                        ]}
                        onChange={change('type')}
                        medium
                        style={{ width: '100%' }}
                        label="Kontakttype"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ContainerSizeSetting
                        containerSize={module.data.containerSize}
                        onChange={change('containerSize')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={module.data.margin || ''}
                        onChange={change('margin')}
                    />
                </Col>
            </Row>
            {module.data.type === 'regular' && (
                <>
                    <Row>
                        <Col width={[12]} className="mb-24">
                            <Input
                                value={module.data.buttonText}
                                onChange={change('buttonText')}
                                medium
                                style={{ width: '100%' }}
                                label="Knappetekst"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col width={[12]} className="mb-24">
                            <Input
                                value={module.data.modalTitle}
                                onChange={change('modalTitle')}
                                medium
                                style={{ width: '100%' }}
                                label="Modal title"
                                multiline
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col width={[12]} className="mb-24">
                            <Input
                                value={module.data.modalDescription}
                                onChange={change('modalDescription')}
                                medium
                                style={{ width: '100%' }}
                                label="Modal description"
                                multiline
                            />
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default ContactCallbackModuleSettings;
