import { curry } from 'ramda';

import { ui, Row, Col } from '/front';

export default function TipModuleSettings({ module, onChange }) {
    const { ColorPicker, Select, ImageInput, Slider } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        backgroundColor,
        backgroundImage,
        textAlignment,
        textColor,
        margin,
    } = module.data;

    return (
        <>
            <Row>
                <Col width={6} className="mb-24">
                    <Select
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <Select
                        value={textAlignment}
                        options={['center', 'left', 'right']}
                        onChange={change('textAlignment')}
                        medium
                        style={{ width: '100%' }}
                        label="Text alignment"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <Select
                        value={textColor}
                        options={[
                            { name: 'balck', value: '#333333' },
                            { name: 'white', value: '#ffffff' },
                        ]}
                        onChange={change('textColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Text color"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <ColorPicker
                        value={backgroundColor}
                        onChange={change('backgroundColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Background color"
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <Select
                        label="Overlay color"
                        value={module.data.overlayColor || 'black'}
                        onChange={change('overlayColor')}
                        options={['black', 'white']}
                        style={{ width: '100%' }}
                        medium
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <Slider
                        label="Overlay opacity"
                        value={module.data.overlayOpacity || 0}
                        onChange={change('overlayOpacity')}
                        medium
                    />
                </Col>
                <Col width={6} className="mb-24">
                    <ImageInput
                        value={backgroundImage}
                        onChange={change('backgroundImage')}
                        label="Background image"
                        width={275}
                        height={100}
                    />
                </Col>
            </Row>
        </>
    );
}
