import React, { useState } from 'react';
import styled from 'styled-components';

import LegacyInput from '../../ui/LegacyInput';
import Button from '/components/ui/buttons/Button';
import { isEmail } from '/lib/helpers';
import { useOrderState } from '/store/orderStore';
import { EmailSubscriptionType } from '/types/EmailSubscriptionType';
import newsletterApi from '/api/newsletterApi';

export default function NotificationForm() {
    const postCode = useOrderState((state) => state.postCode);
    const setOrderPostCodeNotificationRequest = useOrderState(
        (state) => state.setOrderPostCodeNotificationRequest
    );

    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const onSubmitButtonCLick = async () => {
        if (submitting) return;
        setSubmitting(true);

        try {
            await newsletterApi.subscribe(postCode, email, [
                EmailSubscriptionType.HEAT_PUMP_JOBS,
                EmailSubscriptionType.SOLAR_JOBS,
                EmailSubscriptionType.ELECTRICIAN_JOBS,
            ]);
            setEmail('');
            setOrderPostCodeNotificationRequest(true);
        } catch (error) {
            // Handle errors
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Wrapper className="pb-60">
            <div className="input-container">
                <LegacyInput
                    icon="mail"
                    placeholder="E-postadrese"
                    name="post-code-email"
                    label="Få beskjed"
                    value={email}
                    onChange={setEmail}
                />
                <Button
                    variant="secondary"
                    size="medium"
                    className="ml-24"
                    disabled={!isEmail(email)}
                    busy={submitting}
                    onClick={onSubmitButtonCLick}
                >
                    Send
                </Button>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
    @media (max-width: 767px) {
        margin-top: -20px;
    }
    .input-container {
        display: flex;
        align-items: flex-end;
        button {
            min-width: 75px;
        }
    }
`;
