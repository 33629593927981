import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import postCodeApi from '/api/postCode';
import LegacyInput from '../../ui/LegacyInput';
import Button from '/components/ui/buttons/Button';
import Description from '/components/ui/typography/Description';
import { useOrderState } from '/store/orderStore';

interface Props {
    afterCheck: () => void;
}

export default function CodeCheckerForm({ afterCheck }: Props) {
    const setOrderPostCodeValidity = useOrderState(
        (state) => state.setOrderPostCodeValidity
    );
    const setOrderPostCode = useOrderState((state) => state.setOrderPostCode);
    const setOrderPostCodeSupportedJobTypes = useOrderState(
        (state) => state.setOrderPostCodeSupportedJobTypes
    );

    const [code, setCode] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const router = useRouter();

    useEffect(() => {
        if (router.query.postnummer) {
            const queryCode = Array.isArray(router.query.postnummer)
                ? router.query.postnummer[0]
                : router.query.postnummer;
            setCode(queryCode);
            checkCode(queryCode);
        }
    }, [router.query]);

    const checkCode = async (code: string) => {
        if (submitting) return;
        setSubmitting(true);

        const resp = await postCodeApi.checkPostCode(code);

        setOrderPostCode(code);

        if ('error' in resp) {
            setOrderPostCodeValidity(false);
        } else {
            setOrderPostCodeValidity(true);
            setOrderPostCodeSupportedJobTypes(resp.data.jobTypes || []);
        }

        setSubmitting(false);

        afterCheck();
    };

    return (
        <Wrapper className="pt-60 pb-60">
            <div>
                <div className="input-container">
                    <LegacyInput
                        icon="pin"
                        name="code"
                        label="Postnummer"
                        autoFocus
                        type="tel"
                        value={code}
                        onChange={setCode}
                        rules={{ maxLength: 4 }}
                        onKeyPress={(
                            e: React.KeyboardEvent<HTMLInputElement>
                        ) =>
                            e.key === 'Enter' && !((code || '').length < 4)
                                ? checkCode(code)
                                : null
                        }
                    />
                    <Button
                        variant="secondary"
                        size="medium"
                        className="ml-24"
                        disabled={(code || '').length < 4}
                        busy={submitting}
                        onClick={() => checkCode(code)}
                    >
                        Sjekk
                    </Button>
                </div>
                <Description className="pt-16">
                    Sjekk tilgjengelighet der du bor
                </Description>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-container {
        display: flex;
        align-items: flex-end;
        button {
            min-width: 75px;
        }
    }
`;
